<template>
    <h4 class="not-verified-message position-relative" v-html="$t('Your subscription will be canceled on {DATE}.', {DATE: formattedDate})"></h4>
</template>

<script>

export default {
    props: ['date'],
    computed: {
        formattedDate() {
            return this.date ? new Date(this.date).toISOString().split('T')[0] : '';
        }
    }
}
</script>

<style scoped>
.not-verified-message {
    padding: 15px;
    font-size: 14px;
    line-height: 1.5;
    background: #ff5f741a;
    border-radius: 10px;
    color: #ff5f74;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 30px 0;
}

.not-verified-message a.control-button {
    padding: 6px 12px;
}

a.link-help {
    top: 10px;
    position: absolute;
    right: 15px;
    font-size: 14px;
    color: #597dfc;
}

@media all and (max-width: 1400px) {
    .not-verified-message {
        margin: 20px 20px 0;
    }
}
@media (max-width: 991px) {
    .not-verified-message {
        /*margin: 90px 20px 0 !important;*/
        font-size: 12px;
        padding: 15px 20px;
    }

    .not-verified-message .link-help {
        font-size: 10px;
    }
}

@media all and (max-width: 767px) {
    .not-verified-message {
        margin: 15px 15px 0;
    }
}
</style>

